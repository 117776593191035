<template>
  <div class="main-cnt statement-container">
    <el-row :gutter="10">
      <el-col :span="2">
        <el-select v-model="formData.projectId" placeholder="请选择项目">
          <el-option
            :label="item.p_name"
            :value="item.p_id"
            v-for="item in projectOptions"
            :key="item.p_id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          :disabled="!formData.projectId ? true : false"
          v-model="formData.scenicId"
          placeholder="请选择景区"
        >
          <el-option
            :label="item.s_name"
            :value="item.s_id"
            v-for="item in scenicOptions"
            :key="item.s_id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-row>
          <el-col :span="5" class="text-center">
            <span>支付时间</span>
          </el-col>
          <el-col :span="9">
            <el-date-picker
              :clearable="false"
              v-model="formData.stime"
              type="date"
              placeholder="支付时间开始"
              @change="changeStime"
            />
          </el-col>
          <el-col :span="1" class="text-center">
            <span>-</span>
          </el-col>
          <el-col :span="9">
            <el-date-picker
              :clearable="false"
              v-model="formData.etime"
              type="date"
              placeholder="支付时间结束"
              @change="changeEtime"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-select
          v-if="isTableDetail"
          v-model="queryData.spotId"
          multiple
          collapse-tags
          clearable
          placeholder="请选择景点"
          :disabled="!formData.scenicId ? true : false"
        >
          <el-option
            :label="item.name"
            :value="item.keyValue"
            v-for="item in spotOptions"
            :key="item.keyValue"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-if="isTableDetail"
          v-model="queryData.payway"
          multiple
          clearable
          collapse-tags
          placeholder="请选择支付方式"
        >
          <el-option
            :label="item.orp_name"
            :value="item.orp_id"
            v-for="item in orderTypeOptions"
            :key="item.orp_id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" class="action-btn">
        <template v-if="!showEchart">
          <el-tooltip
            class="item"
            effect="dark"
            content="导出"
            placement="left"
          >
            <span class="border-icon" @click="handleExport">
              <i class="iconfont icon-a-lujing207"></i
            ></span>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="打印"
            placement="left"
          >
            <span class="border-icon mr-10" @click="handlePrint">
              <i class="iconfont icon-a-lujing206"></i
            ></span>
          </el-tooltip>
          <el-button @click="handleReset">重置</el-button>
        </template>
        <el-button type="primary" @click="handleQuery">{{
          showEchart ? "明细" : "统计"
        }}</el-button>
      </el-col>
    </el-row>
    <div class="content">
      <ScenicEcharts
        v-if="showEchart"
        :queryData="lastEchartQuery"
        @paywayClick="paywayClick"
        @spotClick="spotClick"
      ></ScenicEcharts>

      <ScenicTable
        v-if="!showEchart"
        ref="scenicTableRef"
        :echartClick="echartClick"
        @showSelect="showSelect"
        :queryData="lastTableQuery"
      ></ScenicTable>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive, watch, computed } from "vue";
import { BasicApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import ScenicEcharts from "../components/ScenicEcharts.vue";
import ScenicTable from "../components/ScenicTable.vue";
import dayjs from "dayjs";
export default {
  components: {
    ScenicEcharts,
    ScenicTable,
  },
  setup() {
    onMounted(() => {
      getOrderType();
      getProjectData();
    });
    const showEchart = ref(true);
    const isTableDetail = ref(false); //是否为《明细表》
    function showSelect(flag) {
      //显示支付方式选择框
      isTableDetail.value = flag;
    }
    const formData = reactive({
      projectId: "",
      scenicId: "",
      stime: new Date(),
      etime: new Date(),
    });
    const queryData = ref({
      spotId: [],
      payway: [],
    });
    const projectOptions = ref([]);
    const getProjectData = () => {
      BasicApi.projectSelect().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            projectOptions.value = res.Data;
            if (!formData.projectId) {
              formData.projectId = projectOptions.value[0].p_id;
            }
          }
        } else {
          let msg = res.Message ? res.Message : "获取数据失败！";
          ElMessage.error(msg);
        }
      });
    };
    const orderTypeOptions = ref([]);
    const getOrderType = () => {
      BasicApi.paywayOptions().then((res) => {
        if (res.Code === 200) {
          orderTypeOptions.value = res.Data;
        } else {
          let msg = res.Message ? res.Message : "获取支付方式失败！";
          ElMessage.error(msg);
        }
      });
    };
    const scenicOptions = ref([]);
    watch(
      () => formData.projectId,
      (v) => {
        if (v) {
          BasicApi.getScenicByProject({ pid: v }).then((res) => {
            if (res.Code === 200) {
              if (res.Data.length) {
                scenicOptions.value = res.Data;
                if (!formData.scenicId) {
                  formData.scenicId = scenicOptions.value[0].s_id;
                }
              }
            } else {
              let msg = res.Message ? res.Message : "获取景区数据失败！";
              ElMessage.error(msg);
            }
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const spotOptions = ref([]);
    watch(
      () => formData.scenicId,
      (v) => {
        if (v) {
          BasicApi.rolebindList({ s_id: v }).then((res) => {
            if (res.Code === 200) {
              if (res.Data && res.Data.length > 0) {
                res.Data.forEach((item) => {
                  if (item.type == 1) {
                    item.id = item.s_id;
                    item.keyValue = item.type + "-" + item.s_id;

                    item.name = "景区-" + item.s_name;
                  } else {
                    item.id = item.sp_id;
                    item.keyValue = item.type + "-" + item.sp_id;
                    item.name = "景点-" + item.sp_name;
                  }
                });
              }
              spotOptions.value = res.Data;
            } else {
              let msg = res.Message;
              ElMessage.error(msg);
            }
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    function changeStime(data) {
      if (formData["etime"] && dayjs(formData["etime"]).isBefore(dayjs(data))) {
        //如果结束时间有值 且 在开始时间之前
        formData["stime"] = formData["etime"];
        formData["etime"] = data;
      } else {
        formData["stime"] = data;
      }
    }
    function changeEtime(data) {
      if (formData["stime"] && dayjs(formData["stime"]).isAfter(dayjs(data))) {
        formData["etime"] = formData["stime"];
        formData["stime"] = data;
      } else {
        formData["etime"] = data;
      }
    }
    const handleQuery = () => {
      showSelect(false);
      echartClick.value = false;
      showEchart.value = !showEchart.value;
      if (showEchart.value) {
        queryData.value = {
          spotId: [],
          payway: [],
        };
      }
    };
    function handleReset() {
      formData["stime"] = new Date();
      formData["etime"] = new Date();
      queryData.value = {
        spotId: [],
        payway: [],
      };
    }
    // 是否点击图表
    const echartClick = ref(false);
    // 点击了图表-支付方式后跳转明细表
    function paywayClick(data) {
      echartClick.value = true;
      queryData.value.payway = [data];
      queryData.value.spotId = [];
      showEchart.value = false;
    }
    function spotClick(data) {
      echartClick.value = true;
      queryData.value.spotId = [data];
      queryData.value.payway = [];
      showEchart.value = false;
    }
    const lastTableQuery = computed(() => {
      return {
        ...queryData.value,
        stime: dayjs(formData.stime).format("YYYY-MM-DD"),
        etime: dayjs(formData.etime).format("YYYY-MM-DD"),
        scenicId: formData.scenicId,
      };
    });
    const lastEchartQuery = computed(() => {
      return {
        stime: dayjs(formData.stime).format("YYYY-MM-DD"),
        etime: dayjs(formData.etime).format("YYYY-MM-DD"),
        scenicId: formData.scenicId,
      };
    });
    const scenicTableRef = ref(null);
    function handleExport() {
      scenicTableRef.value.handleExport();
    }
    function handlePrint() {
      scenicTableRef.value.handlePrint();
    }
    return {
      handleQuery,
      formData,
      projectOptions,
      scenicOptions,
      spotOptions,
      changeEtime,
      changeStime,
      queryData,
      orderTypeOptions,
      paywayClick,
      spotClick,
      handleReset,
      lastTableQuery,
      showEchart,
      handleExport,
      handlePrint,
      scenicTableRef,
      lastEchartQuery,
      showSelect,
      isTableDetail,
      echartClick,
    };
  },
};
</script>

<style lang="scss">
.main-cnt {
  height: auto !important;
}
</style>

